import { ref, get, set, remove } from "firebase/database"; 
import { database } from '../firebase/config';


const COLLECTION_NAME = 'categories';

/**
 * Get all categories from Realtime Database.
 * @returns {Promise<Array>} Array of categories
 */

export const getCategory = async () => {
  try {
    const categoriesRef = ref(database, COLLECTION_NAME);
    const snapshot = await get(categoriesRef);

    if (snapshot.exists()) {
      return Object.entries(snapshot.val()).map(([id, data]) => ({ id, ...data }));
    } else {
      console.log("No categories found in Realtime Database.");
      return [];
    }
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};

/**
 * Add a new category to Realtime Database.
 * @param {Object} category - The category to add
 * @returns {Promise<Object>} The added category with its ID
 */

export const addCategory = async (category) => {
  try {
    const newCategoryRef = ref(database, `${COLLECTION_NAME}/${category.id}`);
    await set(newCategoryRef, category);
    return category;
  } catch (error) {
    console.error("Error adding category:", error);
    throw error;
  }
};

/**
 * Update an existing category in Realtime Database.
 * @param {Object} category - The category to update, must include id
 * @returns {Promise<Object>} The updated category
 */

export const updateCategory = async (categoryId, category) => {
  try {
    const categoryRef = ref(database, `${COLLECTION_NAME}/${categoryId}`);
    await set(categoryRef, category);
    return category;
  } catch (error) {
    console.error("Error updating category:", error);
    throw error;
  }
};

/**
 * Delete a category from Realtime Database.
 * @param {string} categoryId - The ID of the category to delete
 * @returns {Promise<Object>} The deleted category ID
 */
export const deleteCategory = async (categoryId) => {
  try {
    const categoryRef = ref(database, `${COLLECTION_NAME}/${categoryId}`);
    await remove(categoryRef);
    return { id: categoryId };
  } catch (error) { 
    console.error("Error deleting category:", error);
    throw error;
  }
};

export const getCategoryById = async (categoryId) => {
  try {
    const categoryRef = ref(database, `questions/${categoryId}`);
    const snapshot = await get(categoryRef);
    return snapshot.val();
  } catch (error) {
    console.error("Error fetching category by ID:", error);
    throw error;
  }
};  
