import { Button, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { deleteQuestion } from "../../api/question";
import CustomTable from "../../components/customTable/CustomTable";
import { getQuestion } from "../../api/question";
import { getCategory } from "../../api/categoryApi";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Iconify from "../../components/Iconify";
import { useAdminContext } from "../../Hooks/AdminContext";
import { makeStyles } from "@mui/styles"; 
import { getCategoryById } from "../../api/categoryApi";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const QuestionList = () => {
  
  const navigate = useNavigate();
  const { setnavbarTitle } = useAdminContext();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [questions, setQuestions] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    setnavbarTitle("Questions");
    fetchCategories();
  }, []);
  useEffect(() => {
    if (categories.length > 0) {
      getQuestionList();
    }
  }, [categories]);

  const fetchCategories = async () => {
      const response = await getCategory();
        const activeCategories = response.filter(category => category.status==true)
          .map(category => ({ id: category.id, name: category.title }));
        setCategories(activeCategories);
     
  };
  const getQuestionList = async () => {
    setIsLoading(true);
      const response = await getQuestion(); 
      const formattedQuestions = response.map(question => ({
   
        ...question,
        content:{
          content:question.content,
          type:question.type,
        },
        category:categories.find(category => category.id === question.categoryId)?.name || 'Unknown Category',
        createdAt: new Date(question.createdAt).toLocaleDateString('en-US', {
          day: '2-digit',
          month: 'short',
          year: '2-digit'
        })
      }));  
      setQuestions(formattedQuestions);
      setIsLoading(false);
  };
  const handleEdit = (value) => {
    navigate(`/questions/add-question/${value?.id}`, {
      state: {
        id: value.id,
        options: value.options,
        name: value.name,
        questionType: value.questionType,
        categoryId: value.category,
        createdBy: value.createdBy,
        createdAt: value.createdAt,
      },
    });
  };
  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteDoc(value);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const handleDelete = async () => {
    setOpenDelete(false);
      const result = await deleteQuestion(deleteDoc.id);
        setQuestions((prevQuestions) => {
          return prevQuestions.filter((question) => question.id !== deleteDoc.id);
        });
        enqueueSnackbar(result.message, { variant: "success" });
  };
  
  const handleNavigate = () => {
    navigate("/questions/add-question");
  };
 
  const TABLE_HEAD = [
    { id: "id", label: "id",},
    { id: "content", label: "Question" },
    { id: "type", label: "Type" },
    { id: "category", label: "Category" },
    { id: "createdAt", label: "Created at" },
    { id: "action", label: "Action", type: "action" },
  ];

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-8"></div>
          <div className="col-4 mt-2  ps-0" style={{ textAlign: "end" }}>
            <div className="d-flex   justify-content-end mb-3 add-button">
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={handleNavigate}
                className="capitalized"
              >
                Add Question
              </Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-1 ">
            <CustomTable
              TABLE_HEAD={TABLE_HEAD}
              data={questions} // Use the fetched questions instead of teamList
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
            />
          </div>
        </div>
        <DeleteConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete?"}
          handleAgree={handleDelete}
        />
      </div>
    </>
  );
};

export default QuestionList;
