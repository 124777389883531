import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  TextField,
  FormHelperText,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useAdminContext } from "../../Hooks/AdminContext";
import { addCategory, updateCategory } from "../../api/categoryApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AddOrUpdateCategories = ({ type }) => {
  const classes = useStyles();
  const { setnavbarTitle } = useAdminContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    description: "",
  });
  const [errors, setErrors] = useState({
    title: '',
    description: ''
  });

  const handleChange = (e) => {
    let name = e.target.name;
    let newValue = e.target.value;
    setInputs((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({ title: '', description: '' });
    let hasError = false;
    if (!inputs.title.trim()) {
      setErrors(prev => ({ ...prev, title: 'Title is required' }));
      hasError = true;
    } else if (inputs.title.length > 100) {
      setErrors(prev => ({ ...prev, title: 'Title cannot be more than 100 characters' }));
      hasError = true;
    }

    if (inputs.description.length > 500) {
      setErrors(prev => ({ ...prev, description: 'Description cannot be more than 500 characters' }));
      hasError = true;
    }

    if (hasError) return;

    setIsLoading(true);

    try {
      const categoryData = {
        title: inputs.title.trim(),
        status: inputs.status,
        description: inputs.description.trim(),
        id: type === "edit" ? state._id : crypto.randomUUID().slice(0, 4), // Generate a unique ID
        createdAt: new Date().toISOString(),
        createdBy: "Admin",
      };

      let result;
      if (type === "edit") {
        result = await updateCategory(state._id, categoryData);
      } else {
        result = await addCategory(categoryData);
      }

      enqueueSnackbar("Category successfully " + (type === "edit" ? "updated" : "added"), { variant: "success" });
      navigate(-1);
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar(error.message || "An error occurred while processing the category", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state) {
      setInputs(state);
    }
  }, [state]);
  useEffect(() => {
    setnavbarTitle("");
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex ">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-2">{`${type == "edit" ? "Edit" : "Add"} Category`}</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                fullWidth
                name="title"
                value={inputs.title}
                onChange={handleChange}
                error={!!errors.title}
                helperText={errors.title}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="Status *"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="mt-4 textarea-block">
                <TextField
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  rows={4}
                  multiline
                  fullWidth
                  size="small"
                  value={inputs.description}
                  name="description"
                  onChange={handleChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
                <FormHelperText>Maximum limit 500 characters</FormHelperText>
              </div>
            </div>
            <div className="text-end ms-auto">
              <Button variant="contained" type="submit">
                {type == "edit" ? "Update" : "Submit"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrUpdateCategories;
