import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
const firebaseConfig = {
    apiKey: "AIzaSyATkImwuD_PPEzLzFs5XGWiUjhXn27WUhs",
    authDomain: "appers-systems.firebaseapp.com",
    projectId: "appers-systems",
    storageBucket: "appers-systems.appspot.com",
    messagingSenderId: "645778921335",
    appId: "1:645778921335:web:a1e6f614ee1537fde33372"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);


export const db = getFirestore(app);
export { auth, database };